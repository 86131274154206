import React from "react";
import {motion} from 'framer-motion';

export const LoginHead = React.memo(loginHead);

export function loginHead(){
    return (
        <div style={{width:"100%", height:50, position:'fixed', background:"transparent", top:0, border:"1px solid", borderColor:'transparent', borderBottomColor:"var(--stroke-color)", display:'flex', flexDirection:'column', justifyContent:"center"}}>
            <p style={{color:"var(--text-color)", fontSize:25, marginLeft:10}} >OneCloud</p>
        </div>
    )
}