import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {Login} from "./Login";
import {Signup} from "./Signup";
import {Main} from "./Main";
import {LoginHead} from './LoginHead';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
        	
            <Route path="/" element={<Navigate to='/Home' replace/>}></Route>          

            <Route path="/login" element={<Login/>}></Route>
        	<Route path="/signup" element={<Signup/>}></Route>
            <Route path="/Home/:path/*" element={<Main/>}/>
            <Route path="/Home" element={<Main/>}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
