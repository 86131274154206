import React from "react";
import {motion} from 'framer-motion';
import {LoginHead} from './LoginHead';
import { parse_jwt, post, getDIR, api } from "./API"
import { MainHead } from "./MainHead";
import {Menu, Option} from "./Menu";
import {NewFolder} from "./NewFolder";

let path, setPath;
let dir, setDir;

let checkedMenu, setCheckedMenuStatus;

function Item(props){
    var src;

    if (props.type == 'folder'){ src = '/icons/folder.png'; }
    if (props.type == "img") { src=api + `getFile?path=${path + "/" + props.title}` }
    if (props.type == "none" || props.type=='pdf') { src="/icons/file.png"; }

    let [menuStatus, setMenuStatus] = React.useState(false);

    function downloadFile(){
        let a = document.createElement("a");
        a.href = api + `getFile?path=${path + "/" + props.title}&token=${localStorage['token']}`;
        a.download = props.title;
    
        
        a.click()
        setMenuStatus(false);
    }

    function downloadPath(){
        let a = document.createElement("a");
        a.href = api + `downloadPath?path=${path + "/" + props.title}&token=${localStorage['token']}`;
        a.download = props.title;
    
        
        a.click()
        setMenuStatus(false);
    }

    function del(){
        post("delete", {token:localStorage['token'], path:path + "/" + props.title}, (data) => {
            setMenuStatus(false);
            loadContent();
        })
    }
   

    function check_change(){
        let elems = document.getElementsByName("checkbox");
        
        let lamp = 0;
        elems.forEach(item => {
            if (item.checked == true) {
                setCheckedMenuStatus(true);
                lamp = 1;
                return 0;
            }
        })
    
        if (!lamp){
            setCheckedMenuStatus(false);
        }
    }

    return (

        <div className='item'>
            <label class="form-control">
              <input type="checkbox" name="checkbox" file={ path + "/" + props.title } filetype={props.type} onChange={check_change} /> 
            </label>

            <img src={src} onClick={()=>{ props.type == 'folder' ? window.open("/" + path + "/" + props.title, "_self"):window.open("?show=" + props.title, "_self")}} />
            <p onClick= {()=>{ props.type == 'folder' ? window.open("/" + path + "/" + props.title, "_self"):window.open("?show=" + props.title, "_self")}}  >{props.title}</p>
            <img src="/icons/options.png" style={{"position":"absolute", right:0, width:15, height:15, padding:10, zIndex:1}} onClick={()=>{setMenuStatus(true)}}/>
            <Menu title={props.title} show={menuStatus} style={{right:0, marginTop: 82, position:"absolute"}} setShow={setMenuStatus} items={(
                <div>
                    <Option icon="/icons/download.png" text="скачать" onClick={ props.type == 'folder' ? downloadPath : downloadFile} />
                    <Option icon="/icons/delete.png" text='удалить' onClick={del} />
                </div>
            )}/>
        </div>
    )
}



var loadContent;
var param;
export function Main(match){
    [path, setPath] = React.useState(window.location.pathname.substring(1, ));
    [dir, setDir] = React.useState( ( path.lastIndexOf("/") == -1 || path.lastIndexOf("/") == path.length) ? path:path.substring(path.lastIndexOf("/"), ) )

    var [content, setContent] = React.useState();


    [checkedMenu, setCheckedMenuStatus] = React.useState(false);
    React.useEffect(()=>{ 
        param = (new URL(document.location)).searchParams.get("show");
        console.log(param);
    }, []);


    let filesList; 
    loadContent = () => {
        
        if (param) { return setContent(<h1 color='white'>Отображение файла</h1>) }

        getDIR(path, (data) => {
            data = data['data']; 
            filesList = data;
            let content = [];

            data.map((item) => {
                content.push(<Item title={item[0]} type={item[1]}/>)
            })
            
            if (content.length == 0) { 
                return setContent(<h3 style={{textAlign:"center", color:"var(--text-color)"}}>Нет файлов</h3>) 
            }
            setContent(content); 
        })    
    }

    React.useState(()=>{
        setPath(decodeURI(window.location.pathname.substring(1, )));
        setDir(decodeURI(( path.lastIndexOf("/") == -1 || path.lastIndexOf("/") == path.length) ? path:path.substring(path.lastIndexOf("/"), ) ))
        
        loadContent();

    
    }, [])
   
    let [uploadingStatus, setUploadingStatus] = React.useState(false); 
    let [ folderMenu, setFolderMenu ] = React.useState(false);

    function uploadFiles(){
        let inp = document.querySelector("input[id='files']");
        inp.click();
    
        inp.onchange = () => {
            let data = new FormData();

            for (let key in inp.files){
                try{
                    data.append("files", inp.files[key], inp.files[key].name);
                } catch{}
            }
            
            const xhr = new XMLHttpRequest();     
            setUploadingStatus(true); 
            const success = new Promise((resolve) => {
                xhr.upload.addEventListener("progress", (event) => {
                    if (event.lengthComputable) { 
                        document.querySelector("#download_progress").value = event.loaded / event.total;
                    }
                });
                
                xhr.addEventListener("loadend", () => {
                   setUploadingStatus(false);
                    loadContent();
                });

                xhr.open("POST", api + `uploadfiles?path=${path}&token=${localStorage['token']}`, true); 
                xhr.send(data);

            });

        }
    }

    function uploadDirectory(){
        let inp = document.querySelector("input[id='directories']");
        inp.click();

        inp.onchange = () => {
            let data = new FormData();
       
            for (let key in inp.files){
                try{
                    data.append("directories", inp.files[key], inp.files[key].webkitRelativePath);
                } catch{}
            } 

            
            const xhr = new XMLHttpRequest();     
                
            const success = new Promise((resolve) => {
                xhr.upload.addEventListener("progress", (event) => {
                    if (event.lengthComputable) { 
                        document.querySelector("#download_progress").value= event.loaded / event.total;
                    
                    }
                });

                
                xhr.addEventListener("loadend", () => {
                   setUploadingStatus(false);
                    loadContent();
                });
            

                xhr.open("POST", api + `upload_directory?path=${path}&token=${localStorage['token']}`, true); 
                xhr.send(data);
                setUploadingStatus(true);
            });
            




        }
    }
    

    function downloadFiles(path){
        let a = document.createElement("a");
        a.href = api + `downloadFiles?path=[${path}]&token=${localStorage['token']}`;
        a.download = 'OneCloud.zip'; 
        
        a.click()
    }
    

    function download_selected(){
        let elems = document.getElementsByName("checkbox");
        let active = [];
       
        let paths = [] 

        elems.forEach(item => { if ( item.checked == true ) { active.push(item) } })
        active.forEach(item => {
            let path = item.getAttribute("file");
            let type = item.getAttribute("filetype");
            

            paths.push(`"${path}"`);
        })
        
        downloadFiles(paths)
        active.forEach(item => {item.checked = false});

        setCheckedMenuStatus(false);
         
    }

    function delete_selected(){
        let elems = document.getElementsByName("checkbox");
        let active = [];
       
        let paths = [] 

        elems.forEach(item => { if ( item.checked == true ) { active.push(item) } })
        active.forEach(item => {
            let path = item.getAttribute("file");
            let type = item.getAttribute("filetype");
            
            post("delete", {"path":path, "token":localStorage['token']}, loadContent);
             
            
        })
        
        active.forEach(item => {item.checked = false});
        setCheckedMenuStatus(false); 
    
    }

    let [uploadMenu, showUploadMenu] = React.useState(false);

    return (
        <div>
            <MainHead uploading={uploadingStatus} />
            <input type="file" style={{"display":"none"}} id='files' multiple="true"/>
            <input type="file" style={{"display":"none"}} id='directories' multiple="true" webkitdirectory="true" directory="true"/>
            {param == null ? ( 
            <div className="MainBox">
                <div className="head">
                    <p className='title'>{dir}</p>
                    <div className="buttonBox">
                        <button className='mkdir' onClick={()=>{setFolderMenu(true)}}> + папка </button>
                        <div className='upload' onClick={()=>{showUploadMenu(true)}}>
                            <img src='/icons/upload.png' />
                            <p>Загрузить</p>
                        </div>

                       
                        <Menu show={uploadMenu} setShow={showUploadMenu} title="Загрузить" style={{position:"absolute", top:136, marginLeft:20}} items={(
                            <div>
                                <Option icon="/icons/file.png" text='файлы' noInvert="true" onClick={uploadFiles}/>
                                <Option icon="/icons/folder.png" text='папку' noInvert="true" onClick={uploadDirectory} />
                            </div>
                        )}/>  
                    </div>

                </div>

                <NewFolder show={folderMenu} setShow={setFolderMenu} callback={(text) => {
                    setFolderMenu(false);
                    post("mkdir", {"path":path + "/" + text, token:localStorage['token']}, (data) => {
                        loadContent();
                    }) 
                }} />

                <div className='checkedMenu' style={{display: checkedMenu ? "flex":"none"}}>
                    <button onClick={download_selected}>Загрузить выбранное</button>
                    <button onClick={delete_selected}>Удалить выбранное</button>
                </div>

                <div className='contentBox'>
                    {content}
                </div>

            </div>) : <iframe src={api + "showFile?path=" + path + "/" + param} width="100%" style={{height:"calc(100vh - 65px)", marginTop:55,border:"none"}}></iframe> }
        
        </div>
    )
}
