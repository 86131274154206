export const api = "https://justtestssl.duckdns.org:8000/";

export function post(path, data, callback){

    let body = new FormData();
    for ( let key in data ){ body.append(key, data[key]); }

    fetch(api + path + "?" + new URLSearchParams(data).toString(), {
        "method":"POST",
        "body":body,
        'credentials': 'include'
    }).then((res) => { return res.json()}).then((data) => {
        if (!("status" in data)){ return alert(data['detail']) }
        if (data['status'] == "error") { return alert(data['message']) }
    
        return callback(data);
    }).catch((err) => {});


}

export function getDIR(path, callback){

    fetch(api + `dir?token=${localStorage['token']}&path=${path}`).then((res) => {return res.json()}).then((data) => {
        if (data['status'] == "error") { return alert(data['message']) }

        return callback(data);
    }).catch((err) => {})


}

export function parse_jwt(callback, path="parse_jwt", data={"token":localStorage['token']}){

    let body = new FormData();
    for ( let key in data ){ body.append(key, data[key]); }
    
    fetch(api + path + "?" + new URLSearchParams(data).toString(), {
        "method":"POST",
        "body":body,
    }).then((res) => {return res.json()}).then((data) => {

        if ( !("status" in data) || (data['status'] == 'error')){  
            return window.open("/login", "_self");
        }
        return callback(data);
    
    }).catch((err) => { return window.open("/login", "_self") })


}
