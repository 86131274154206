import React from "react";
import {motion} from 'framer-motion';
import {LoginHead} from './LoginHead';
import { parse_jwt, post } from "./API"
import { normalizePathname } from "@remix-run/router";


export function Option(props){
    return (
        <motion.nav style={{width:"100%", height:35, display:"flex", background:"var(--bg-color)", flexDirection:'row', alignItems:"center", justifyContent:"flex-start", userSelect:'none',cursor:'pointer', paddingBottom:2, paddingTop:2}} className="Option" onClick={props.onClick}>
            <img src={props.icon} style={{filter:props.noInvert ? "":"invert(0.7)", width:18, height:18, marginLeft:10}} />
            <p style={{color:"var(--text-color)", fontSize:15, marginLeft:10}}>{props.text}</p>

        </motion.nav>
    )
}

export function Menu(props){

    var [menuStatus, setMenuStatus] = React.useState(false); 
    var key = props.key ? props.key : props.title

    const variants = {
        open: { opacity: 1, display:'flex'},
        closed: { opacity: 0, display:'none' },
    }

    React.useEffect(()=>{
        setMenuStatus(props.show);
    }, [props.show]);

    React.useEffect(()=>{
        let listener = document.addEventListener("click", handl);
        function handl(event){
            let node = document.querySelector(`.MENU[id="${key}"]`);
            if (!node){return;}
            if (!node.contains(event.target) && node.style.opacity == "1"){
                props.setShow(false);
                document.removeEventListener("click", listener, true);
            }
        }
    }, [menuStatus]);


    return (
        <motion.nav id={key} initial={{display:"none"}} animate={menuStatus ? "open":"closed"} variants={variants} style={Object.assign({zIndex:100, width:200, border:"1px solid var(--stroke-color)", background:"var(--bg-color)", flexDirection:"column", alignItems:"center"}, props.style)} className='MENU'>
            <div style={{display:"flex", flexDirection:'column', justifyContent:"center", width:"100%", border:"1px solid transparent", borderBottomColor:"var(--stroke-color)", height:40}}>
                <p style={{color:"var(--text-color)", marginLeft:8, userSelect:"none", fontSize:15, paddingBottom:20,whiteSpace:'nowrap',overflow:"hidden", textOverlflow:"ellipsis"}}>{props.title}</p>
            </div>

            <div style={{width:"100%", height:"auto", display:"flex", flexDirection:"column"}}>
                {props.items}
            </div>
        </motion.nav>
    )
}
