import React from "react";
import {motion} from 'framer-motion';
import {LoginHead} from './LoginHead';
import { parse_jwt, post, getDIR, api } from "./API"
import { MainHead } from "./MainHead";
import {Menu, Option} from "./Menu";

export function NewFolder(props){

    React.useEffect(()=>{
        let listener = document.addEventListener("click", handl);
        function handl(event){
            let node = document.querySelector(`.window`);
            console.log("click")
            if (!node){return;}
            if (!node.contains(event.target) && event.target.className != "mkdir"){
                props.setShow(false);
                document.removeEventListener("click", listener, true);
                document.querySelector(".folderInput").value = "";
                
            }
        }
    }, []);




    return (
        <div className='window' style={{display: props.show ? "flex":"none"}}>
            <h4 style={{marginLeft:15}}> Создать Папку </h4>
            <input placeholder="Name" className='folderInput' autocomplette="off" />
            <div className='buttons'>
                <button className='cancel' onClick={()=>{
                    document.querySelector(".folderInput").value = "";
                    props.setShow(false);
                }}>Отмена</button>
                <button className='make' onClick={()=>{
                    let text = document.querySelector(".folderInput").value;
                    document.querySelector(".folderInput").value = "";
                    props.callback(text)
                }} >Создать</button>
            </div>
        </div>
    )
}
