import React from "react";
import {motion} from 'framer-motion';
import {LoginHead} from './LoginHead';
import { post } from "./API"


export function Signup(){

    let signup = () => {
        let login = document.querySelector("#login").value;
        let password = document.querySelector("#password").value;
        
        post("signup", {"login":login, "password":password}, (data) => {
            localStorage['token'] = data['token'];

            document.cookie = `token=${data['token']}; secure=true`;
            window.open("/Home", "_self");
        })
    }

    return (
        <div>
            <LoginHead/>
            
            <motion.div className='loginBox' animate={{y:30}}>
                <div className="head"><p>Создать аккаунт</p></div>
                
                <input id='login' placeholder="login" style={{marginTop:35}} autoComplete="off"/>
                <input id='password' placeholder="password" type='password' autoComplete="off"/>

                <button className='accent' onClick={signup}>Создать</button>
                <button className='second' onClick={()=>{window.open("/login", "_self")}}>Войти</button>

            </motion.div>

        </div>
    )

}
