import React from "react";
import {motion} from 'framer-motion';
import {LoginHead} from './LoginHead';
import { parse_jwt, post } from "./API"
import {Menu, Option} from "./Menu";

var userName, setUserName;
export function MainHead(props){
    [userName, setUserName] = React.useState("");
    var [menuStatus, setMenuStatus] = React.useState(false);
    var [uploadMenu, setUploadMenu] = React.useState(props.uploading);

    React.useEffect(()=>{    
        parse_jwt((data) => {
            setUserName(data['login']);
        })
    },[]);

    React.useEffect(()=>{
        setUploadMenu(props.uploading)
    }, [props.uploading])

    return (
        <div style={{width:"100%", zIndex:100,height:55, position:'fixed', background:"var(--bg-color)", top:0, border:"1px solid", borderBottomColor:"var(--stroke-color)", display:'flex', flexDirection:'row', alignItems:"center"}}>
            <p style={{color:"var(--text-color)", fontSize:25, marginLeft:10}} >OneCloud</p>
            
            <img src='/icons/upload.png' style={{position:"absolute", right:70, filter:"invert(1)", width:16, height:16, cursor:'pointer', display:props.uploading ? "flex":"none"}} onClick={()=>{setUploadMenu(true)}}/>
            <Menu title="Статус загрузки" show={uploadMenu} setShow={setUploadMenu} style={{position:"absolute", top:55, right:0}} items={(
                <progress id='download_progress' value={"0.25"} style={{accentColor:"var(--accent-color)", width:'95%', margin:"0 auto"}} ></progress>
            )} />

            <div onClick={()=>{setMenuStatus(true)}} style={{width:30, height:30, border:"1px solid var(--stroke-color)", borderRadius:100, background:"#1E1E1E", display:'flex', flexDirection:'column', justifyContent:"center", alignItems:"center", position:"absolute", right:25, cursor:"pointer"}}>
                <p style={{color:"var(--text-color)", fontSize:15, userSelect:"none", marginLeft:-1}}>{userName[0] ? userName[0].toUpperCase():userName[0]}</p>
            </div>

            <Menu title={userName} items={(
                <div>
                    <Option icon="/icons/exit.png" text="Выйти" onClick={()=>{ localStorage.removeItem("token"); window.open("/login", "_self"); }}/>
                </div>
            )} style={{position:"fixed", right:0, top:56}} show={menuStatus} setShow={setMenuStatus}/>
        </div>
    )
}
